// Migrated
<template lang="pug">
.charter-search__header.d-flex
  h5.mb-0.font-weight-medium
    slot
  small.ml-3.text-error.blink.blink-title.d-block.px-2.rounded-4(
    v-if="selectError"
  ) {{ selectError }}
  slot(name="content")
  .flex-fill
  button.btn.py-0.d-block.d-lg-none.m-0.font-size-20(
    v-if="showClose"
    type="button"
    @click="$emit('close')"
  )
    fa(icon="times")
  button.btn-rounded.btn-orange.px-px-40.py-2.text-capitalize.text-xl.font-weight-medium(
    v-if="showNext",
    type="button",
    @click="$emit('next')"
  ) {{ $t('formNextButton') }}
  button.btn-rounded.btn-orange.px-px-40.py-2.text-capitalize.text-xl.font-weight-medium(
    v-if="campaign",
    type="button",
    @click="$emit('next')"
  ) Sök
</template>

<script>
export default defineNuxtComponent({
  props: {
    selectError: {
      type: String,
      default: null,
    },

    campaign: {
      type: Boolean,
      default: false,
    },

    showNext: {
      type: Boolean,
      default: false
    },

    showClose: {
      type: Boolean,
      default: true
    }
  },

  emits: ['close', 'next'],
})
</script>
